<!-- Boeing 747-4J6(LCF) Tail Number N747BC.
Boeing 747-409(LCF) Tail Number N780BA.
Boeing 747-409(LCF) Tail Number N249BA.
Boeing 747-4H6(LCF) Tail Number N718BA. -->
<template>
   <main class="main_v8" v-dragscroll>
      <!-- <h1>Global Operations</h1> -->
        <div @click="showAlarms = true; active = true;" class="bell" v-if="!showAlarms"></div>
      <div class="container" id="container">
        <div class="logo"></div>
      </div>
      <livedata
          :src="url"
          :scanrate="scanrate"
          :controls="controls"
          @on-data="updatedata">
      </livedata>
      <alarmdata
          :src="url2"
          :scanrate="scanrate"
          :controls="controls"
          @on-data="updatealarmdata">
      </alarmdata>
			<Draggable :update="active" v-if="showAlarms" handle="h3" @mousedown.stop @touchstart.stop>
				<div class="alarms">
					<h3>Alarms
            <!-- <span class="pin" :style="!active ? {backgroundImage: 'url(img/boeing/pushpin_green.svg)'} : null" @click="active = !active"></span> -->
            <span class="cross" @touchend="showAlarms = false" @click="showAlarms = false"></span>
          </h3>
					<ul>
						<li v-for="line in alarms" :key="line">{{line}}</li>
					</ul>
				</div>
			</Draggable>
   </main>
</template>

<script setup>
  import HighchartsVue from 'highcharts-vue'
  import * as Highcharts from 'highcharts/highmaps';
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  const store = useStore();
  import { useRouter, useRoute } from 'vue-router';
  import livedata from '../components/livedata/data.vue';
  import alarmdata from '../components/livedata/data.vue';


const router = useRouter();
const route = useRoute();

  let data = ref();
  let alarms = ref();
  const url = ref("https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getActive.php");
  const url2 = ref("https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getAlarmHistory.php");
  const scanrate = ref(5000);
  const controls = ref(false);
  const showAlarms = ref(true);
  const active = ref(true);
  const sites = ["PAE","IAB","CHS","TAR","NGO"]; 
  const units = {
    "PAE":["MTS6","MTS9","LCL6","LCL8"],
    "IAB":["MTS5","MTS7","LCL5","LCL7"],
    "CHS":["MTS8","MTS10","LCL9","LCL10"],
    "TAR":["MTS2","MTS3","LCL2","LCL4"],
    "NGO":["MTS1","MTS4","LCL1","LCL3"]
  };
  let vehicleStatus = {"PAE":"","IAB":"","CHS":"","TAR":"","NGO":""};
  let siteStatus = {"PAE":"#aaa","IAB":"#aaa","CHS":"#aaa","TAR":"#aaa","NGO":"#aaa"};
  
  let hc = {};

  async function firstPass() {
    const topology = await fetch(
        'https://code.highcharts.com/mapdata/custom/world.topo.json'
    ).then(response => response.json());

    hc = Highcharts.mapChart('container', {
        chart: {
            map: topology,
            useHTML: true,
            backgroundColor: 'transparent',
            borderColor: '#787878',
        },
        colorAxis: {
          
        },
        tooltip: {
            formatter: function () {
                return this.point.tooltipHTML;          
            },
            useHTML: true,
            borderRadius: 16,
            borderWidth: '3px',
            borderColor: '#099F45',
      			shape: "square",
            padding: 16,
            shadow: false,
            stickOnContact: true,
        },
        plotOptions: {
          series: {
              stickyTracking: false,
          },
          mappoint: {
            point: {
              events: {
                mouseOver: function (e) {
                  setTimeout(function hangAnId() {
                    const locations = document.querySelectorAll('#LCL1, #LCL2, #LCL3, #LCL4, #LCL5, #LCL6, #LCL7, #LCL8, #LCL9, #LCL10, #MTS1, #MTS2, #MTS3, #MTS4, #MTS5, #MTS6, #MTS7, #MTS8, #MTS9, #MTS10');
                    for (let i = 0; i < locations.length; i++) {
                      locations[i].addEventListener("click", function() {
                        store.state.operationsLocation = this.id;
                        router.push('/operationsmonitoring');
                      });
                    };
                  }, 100);
                },
              }
            },
            cursor: 'pointer',
          },
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
        },
        series: [{
            name: 'Countries',
            color: '#666666',
            borderColor: '#787878',
            enableMouseTracking: false,
            states: {
              inactive: {
                enabled: false
              },
            },
        }, {
            type: 'mappoint',
            name: 'sites',
            data: []
        }],
    });
  }
  
  function updatedata(val) {
    data.value = val;

    if (val != -1) {
      sites.forEach((site) => {
        let arr = [val.dates[site].replace(/\s.*/, ''), val.dates[site].replace(/\S+\s/, '')]
        vehicleStatus[site] = "<p style='font-size: 16px; margin: 0 0 8px 0;color: #252525;'>" + site + "</p>";
        vehicleStatus[site] += "<span style='font-size: 14px; color: #787878;'>" + arr[0] + "</span><br>";
        vehicleStatus[site] += "<span style='font-size: 14px; color: #787878;'>" + arr[1] + "</span><br>";
        vehicleStatus[site] += '<div style="display: flex; flex-wrap: wrap; justify-content: space-between; width: 122px; font-weight: 400;">';
        let cnt = 0;
        units[site].forEach((unit) => {
          try {
            if (val[site][unit] == '1') {
              vehicleStatus[site] += "<a id='" + unit + "' style='text-decoration: none; font-size: 17px; height: 28px; line-height: 28px; margin-top: 4px; box-sizing: border-box; text-align: center; border: 1px solid green;background-color:green;color:white;border-radius:8px; width: calc(50% - 2px);'>" + unit + "</a>";
              cnt++;
            } else {
              vehicleStatus[site] += "<a id='" + unit + "' style='text-decoration: none; font-size: 14px; height: 28px; line-height: 28px; margin-top: 4px; box-sizing: border-box; text-align: center; border: 1px solid #777;background-color:#777;color:white;border-radius:8px; width: calc(50% - 2px);'>" + unit + "</a>";
            }
          } catch(e) {

          }
        });
        vehicleStatus[site] += "</div>";
        if (cnt > 0) {
          siteStatus[site] = 'limegreen';
        }
        else {
          siteStatus[site] = '#aaa';
        }
      });
    }
    const datas = [{
        name: '',
        lat: 47.9081,
        lon: -122.2814,
        color: siteStatus["PAE"],
        tooltipHTML: vehicleStatus["PAE"],
        marker: {
            radius: 10
        }
    }, {
        name: '',
        lat: 34.8590,
        lon: 136.8146,
        color: siteStatus["NGO"],
        tooltipHTML: vehicleStatus["NGO"],
        marker: {
            radius: 10
        }
    }, {
        name: '',
        lat: 32.8917,
        lon: -80.0395,
        color: siteStatus["CHS"],
        tooltipHTML: vehicleStatus["CHS"],
        marker: {
            radius: 10
        }
    }, {
        name: '',
        lat: 40.5178,
        lon: 17.3978,
        color: siteStatus["TAR"],
        tooltipHTML: vehicleStatus["TAR"],
        marker: {
            radius: 10
        }
    }, {
        name: '',
        lat: 37.6347,
        lon: -97.2564,
        color: siteStatus["IAB"],
        tooltipHTML: vehicleStatus["IAB"],
        marker: {
            radius: 10
        }
      }
    ];
    
    hc.series[1].setData(datas);
  }

  function updatealarmdata(val) {
    alarms.value = val;
  }

  firstPass();
</script>

<style lang="scss" scoped>
  .main_v8 {
    // top: 64px !important;
    // bottom: 72px;
    background-color: #ffffff;
    padding: 0px;
    overflow-y: hidden !important;
    overflow-x: auto !important;
  }
  .bell{
    height: 64px;
    width: 64px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    background-color: #f2f2f2;
    background-image: url(../../public/img/boeing/alarm.svg);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 16px;
    z-index: 1;
  }
  .container {
    // height: calc(100% - 180px);
    margin: 0 auto;
    aspect-ratio: 2/1;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-height: 480px) and (orientation: landscape){
      top: -10%;
      height: 120%
    }
    .logo{
      height: 44px;
      width: 188px;
      position: absolute;
      top: 28px;
      left: 16px;
      background-image: url(../../public/img/boeing/boeing.png);
      background-repeat: no-repeat;
      background-position: center;
    }
	}
	.alarms{
		position: fixed;
		z-index: 1;
		right: 80px;
		bottom: 100px;
		height: 198px;
		width: 800px;
		overflow: hidden;
		border-radius: 16px;
    span{
      top: 8px;
      height: 32px;
      width: 32px;
      position: absolute;
      cursor: pointer;
    }
    .cross{
      background-image: url(../../public/img/boeing/close.svg);
      right: 20px;
    }
    .pin{
      background-image: url(../../public/img/boeing/pushpin.svg);
      right: 64px;
    }
    @media screen and (min-width: 481px) and (max-width: 900px){
      max-width: calc(100% - 32px);
      left: 16px;
      right: auto;
    }
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      max-width: calc(100% - 32px);
      left: 16px;
      right: auto;
    }
    
    @media screen and (max-height: 480px) and (orientation: landscape){
      max-width: 340px;
      left: auto;
      right: 16px;
    }
    
		h3{
      cursor: grab;
			background-color: #f8f8f8;
			height: 48px;
			line-height: 48px;
			padding-left: 20px;
			margin: 0px;
			position: sticky;
			top: 0px;
			font-weight: 600;
			font-size: 18px;
		}
    ul::-webkit-scrollbar {
      display: none;
    }
		ul{
			overflow: auto;
			height: 152px;
			margin: 0px;
			padding: 0px;
      -ms-overflow-style: none;
      scrollbar-width: none;
			li{
				font-size: 12px;
				line-height: 30px;
				font-weight: 500;
				padding-left: 10px;
			}
			li:nth-child(odd){
				background-color: #e9e9e9;
			}
			li:nth-child(even){
				background-color: #f2f2f2;
			}
		}
	}
	// svg > .test_class{
  //   background-color: red;
  // }
	// .highcharts-label{
	// 		background-color: red;
	// 	>span{
	// 		background-color: red;
	// 	}
	// }
	// .highcharts-tooltip{
	// 		background-color: red;
	// 	>span{
	// 		background-color: red;
	// 	}
	// }
</style>
